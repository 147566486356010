import { Footer, Header, Modals, Welcome, Index } from '../components/scripts.js';

$(() => {
	//| ⭐ Components

	Header();
	Footer();
	Index();

	//| 🌟 Pages

	Welcome();

	//| 🌠 Modals
	Modals();
});
