export const Index = () => {
  $('.enter-account').on('click', () => {
    ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'lk'})
    window.open('https://x5paket.ru/', '_blank')
  })

  $('.go-to-pyaterochka').on('click', () => {
    window.open('https://5ka.onelink.me/PnJ3/1yeifv78', '_blank')
  })
  $('.go-to-perekrestok').on('click', () => {
    window.open('https://app.perekrestok.ru/2MFR/jmr03wz0', '_blank')
  })

  const mainSwiper = new Swiper('.mainSwiper', {
    pagination: {
      el: ".swiper-pagination",
    },
    allowTouchMove: false,
    on: {
      slideChange: function (swiper) {
        const index = swiper.activeIndex
        if (index == 0 || index == 4) {
          $('.swiper-custom-swiper-pagination').addClass('dark-color');
        }
        else {
          $('.swiper-custom-swiper-pagination').removeClass('dark-color');
        }
        $('.page-number').text(index + 1)
      }
    }
  })

  Array.from(Array(5).keys()).forEach(index => {
    $(`.main-swipe-to-${index}`).on('click', () => {

      if (index === 1)  {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'start'}) /// start
      } else if (index === 2) {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'dinner-btn'})
      } else if (index === 3) {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: ' supper-btn'})
      } else if (index === 4) {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: ' final-btn'})
      }
      mainSwiper.slideTo(index)
    })
  })

  const resultMealCard = (name, amount, price, percent, balls, image, type) => `
  <div class=" swiper-slide result-slide">
    <div class="result-meal_wrapper">
      <div class="result-meal"><img alt="product" src="./img/result-products/${image}.jpg">
        <div class="result-meal_container">
          <div class="result-meal_header">
            <h3>${name}</h3>
            <p>${amount}</p>
          </div>
          <div class="result-meal_footer">
            <div class="footer_block"> <span>Стоимость</span>
              <div class="price_value">
                <p class="value_number">${price}</p>
                <p class="value_sign">₽</p>
              </div>
            </div>
            <div class="footer_block"><span>кешбэк</span>
              <div class="percent_value">
                <p class="value_number">${percent}</p>
                <p class="value_sign">%</p>
              </div>
            </div>
            <div class="footer_block"> <span>Баллы</span>
              <div class="balls_value"> <img alt="ball" src="./svg/balls_${type}.svg">
                <p class="value_number">${balls}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `

  const continueButton = () => `
  <div class=" swiper-slide result-slide">
    <div class="button-wrapper">
      <button class="full-width small-list-results">
        <p>
          Продолжить
        </p>
      </button>
    </div>
  </div>
  `

  const smallResultMealCard = (name, amount, price, balls, image, type) => `
  <div class="meal-item">
    <div class="main-data"><img class="meal-icon" alt="product" src="./img/small-result-products/${image}.png">
      <div class="main-info">
        <p>${name}</p>
        <div class="amount">${amount}</div>
      </div>
    </div>
    <div class="secondary-data">
      <div class="price-block">
        <div class="price-value">${price}</div>
        <div class="price-symbol">₽ </div>
      </div>
      <div class="balls-block"> <img alt="balls" src="./svg/balls_${type}.svg">
        <div class="balls-value">${balls}</div>
      </div>
    </div>
  </div>`

  const activeMeals = {};
  const smallListOpen = {};

  const getSmallMealsResultList = async (meal, mealVersionIndex ) => {
    const maxHiddenElements = 3;

    const data = await fetch('../data.json').then(data => data.json())
    const {title2, ballSum, cashback, products} = data.mealResult[meal][mealVersionIndex];

    $(`.${meal}-slide .small-result-slide .dish-header h1`).text(title2);
    $(`.${meal}-slide .balls-block .balls-value`).text(ballSum);
    $(`.${meal}-slide .meal-list_content  .meal-list_content_heder p`).text(`А это ${cashback} только на этих покупках`);


    let resultMealsListString = '';

    for (const productIndex in products) {
      const {name, amount, price, balls, image} = products[productIndex];
      resultMealsListString += smallResultMealCard(name, amount, price, balls, image, meal)
    }

    

    $(`.${meal}-slide .meal-list_content_content`).html(resultMealsListString);

    const smallResultSwiper = new Swiper('.small-result_wrapper', {
      direction: "vertical",
      slidesPerView: 'auto',
      spaceBetween: 0,
      observer: true,
      observerParents: true,
      freeMode: true,
    })

    if (products.length > 3) {
      $(`.${meal}-slide .meal-list_content_footer`).removeClass('display-n')
    }
    const showAllSwitch = $(`#small-${meal}-result-switch`);
    showAllSwitch.on('click', () => {
      if (!smallListOpen[meal]) {
        smallListOpen[meal] = true;
      } else {
        smallListOpen[meal] = false;
      }
      if (smallListOpen[meal]) {
        setTimeout(() => {
          $(`.${meal}-slide .meal-list_content_content`).addClass('expanded')
          $(`.${meal}-slide .hide-button_container span`).text('Скрыть')
          $(`#small-${meal}-result-switch img`).css('transform', 'rotate(0deg)')
        }, 300)
      }
      else {
        $(`.${meal}-slide .meal-list_content_content`).removeClass('expanded')
        $(`.${meal}-slide .hide-button_container span`).text('Раскрыть')
        setTimeout(() => {
          $(`#small-${meal}-result-switch img`).css('transform', 'rotate(180deg)')
        }, 600)
      }

      setTimeout(() => {
        smallResultSwiper.forEach(swiper => {
          swiper.update()
        })
      }, 1000)
    })
  }

  const getMealsResultList = async (activeMeal) => {
    const [meal, version] = activeMeal.split('-');
    const mealVersionIndex = parseInt(version.slice(1)) - 1;
    const data = await fetch('../data.json').then(data => data.json())

    const {title, products} = data.mealResult[meal][mealVersionIndex]
    let resultMealsListString = '';

    $(`.${meal}-slide .result_wrapper .dish-header .container h1`).text(title)

    for (const productId in products) {
      const {name, amount, price, percent, balls, image} = products[productId]
        const productCardString = resultMealCard(name, amount, price, percent, balls, image, meal)
        resultMealsListString += productCardString;
    }
    $(`.${meal}-slide .result_wrapper .swiper-wrapper`).append(resultMealsListString);
    $(`.${meal}-slide .result_wrapper .swiper-wrapper`).append(continueButton())

    $(`.${meal}-slide .small-list-results`).on('click', () => { /// go
      if (meal === 'breakfast') {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: "breakfast-go"})
      } else if (meal === 'lunch') {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: "dinner-go"})
      } else if (meal === 'dinner') {
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: " supper-go"})
        ym(94994504,'reachGoal','onboarding-quiz', {onboarding: " final-scn"})
      }
        
      getSmallMealsResultList(meal, mealVersionIndex)


      $(`.${meal}-slide .small-result_wrapper`).removeClass('display-n');
      $(`.${meal}-slide .result_wrapper`).addClass('display-n');
      $(`.${meal}-slide .result_wrapper .meal-list_content`).append(resultMealsListString)
    })
  }


  const meals = ['breakfast', 'lunch', 'dinner'];
  const bgs = ['rgba(241, 118, 40, 1)', 'rgba(0, 136, 254, 1)', 'rgba(85, 91, 181, 1)'];

  for (let i = 0; i < meals.length; i += 1) {
    activeMeals[meals[i]] = null;


    for (let j = 0; j <= 3; j += 1) {
      $(`#${meals[i]}-v${j}`).on('click', () => {

        if (activeMeals[meals[i]] !== null) {
          $(`#${activeMeals[meals[i]]}`).css('background-color', '').css('color', '');
        }
        $(`#${meals[i]}-v${j}`).css('background-color', bgs[i]).css('color', '#fff');
        activeMeals[meals[i]] = `${meals[i]}-v${j}`;

        setTimeout(() => {
          $(`.${meals[i]}-slide .question_wrapper`).addClass('display-n');
          $(`.${meals[i]}-slide .result_wrapper`).removeClass('display-n');
          if (meals[i] === 'breakfast') {
            ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'breakfast-scn'})  ///// screen big
          } else if (meals[i] === 'lunch') {
            ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'dinner-scn'})
          } else if (meals[i] === 'dinner') {
            ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'supper-scn'})
          }

          getMealsResultList(activeMeals[meals[i]])
        }, 400);
      });
      $(`.${meals[i]}-slide .balls-block img`).attr('src', `./svg/balls_${meals[i]}.svg`);
      $(`.${meals[i]}-slide .hide-button_container img`).attr('src', `./svg/arrow-up-${meals[i]}.svg`);
    }
  }

  const resultSwiper = new Swiper('.result_wrapper', {
    direction: "vertical",
    slidesPerView: 'auto',
    spaceBetween: 0,
    freeMode: true,
    observer: true,
    observerParents: true,
  })


  $('.go-to-promotions:not(.enter-account)').on('click', () => {
    ym(94994504,'reachGoal','onboarding-quiz', {onboarding: 'actions'})
    $('.end-slide').addClass('display-n');
    $('.promotions-purchases-slide').removeClass('display-n');

  })

  const promotionsSwiper = new Swiper('.promotions-wrapper', {
    direction: "vertical",
    slidesPerView: 'auto',
    spaceBetween: 30,
    freeMode: true,
    observer: true,
    observerParents: true,
  })

  const promotionCardSlider = new Swiper('.promotion-card-swiper ', {
    slidesPerView: 2,
    spaceBetween: 8,
  })
}
